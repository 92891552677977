@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./assets/fonts/Gotham-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url(./assets/fonts/GothamLight.otf) format("truetype");
}
@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url(./assets/fonts/Gotham-Book.otf) format("truetype");
}
@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url(./assets/fonts/GothamBold.otf) format("truetype");
}
@font-face {
  font-family: "CormorantBold";
  src: local("CormorantBold"),
    url(./assets/fonts/CormorantInfant-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "CormorantSemiBold";
  src: local("CormorantSemiBold"),
    url(./assets/fonts/CormorantInfant-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "CormorantBoldItalic";
  src: local("CormorantBoldItalic"),
    url(./assets/fonts/CormorantInfant-BoldItalic.ttf) format("truetype");
}

body::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
}
